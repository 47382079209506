import React from "react";

import { ReactComponent as Star } from "../media/images/svg/star.svg";
import { ReactComponent as GoogleLogo } from "../media/images/svg/google_logo.svg";

import AccessNetworks from "../media/images/footer-logos/access-networks.png";
import Audioquest from "../media/images/footer-logos/audioquest-logo.png";
import Barco from "../media/images/footer-logos/barco.png";
import BW from "../media/images/footer-logos/bw.png";
import CoastalSource from "../media/images/footer-logos/coastal-source.png";
import Control4 from "../media/images/footer-logos/control-4.png";
import Focal from "../media/images/footer-logos/focal.png";
import Harman from "../media/images/footer-logos/harman.png";
import JBLSynthesis from "../media/images/footer-logos/jbl-synthesis.png";
import Josh from "../media/images/footer-logos/josh.png";
import Lutron from "../media/images/footer-logos/lutron.png";
import OriginAcustic from "../media/images/footer-logos/origin-acoustic.png";
import Savant from "../media/images/footer-logos/savant.png";
import Seura from "../media/images/footer-logos/seura.png";
import Sonos from "../media/images/footer-logos/sonos.png";
import Sony from "../media/images/footer-logos/sony.png";
import Trinnov from "../media/images/footer-logos/trinnov.png";
import Wattbox from "../media/images/footer-logos/wattbox.png";

import STSLogo from "../media/images/smart-technology-solutions-white-logo.png";
import SCSLogo from "../media/images/smart-construction-solutions-white-logo.png";

function Footer()
{
    const logos =
    [
        { src: AccessNetworks,      alt: "Access Networks Logo",        href: "https://www.accessnetworks.com/"             },
        { src: Audioquest,          alt: "AudioQuest Logo",             href: "https://www.audioquest.com/"                 },
        { src: Barco,               alt: "Barco Logo",                  href: "https://www.barco.com/"                      },
        { src: BW,                  alt: "Bowers & Wilkins Logo",       href: "https://www.bowerswilkins.com/"              },
        { src: CoastalSource,       alt: "Coastal Source Logo",         href: "https://www.coastalsource.com/"              },
        { src: Control4,            alt: "Control4 Logo",               href: "https://www.control4.com/"                   },
        { src: Focal,               alt: "Focal Logo",                  href: "https://www.focal.com/"                      },
        { src: Harman,              alt: "Harman Logo",                 href: "https://www.harman.com/"                     },
        { src: JBLSynthesis,        alt: "JBL Synthesis Logo",          href: "https://www.jblsynthesis.com/"               },
        { src: Josh,                alt: "Josh.ai Logo",                href: "https://www.josh.ai/"                        },
        { src: Lutron,              alt: "Lutron Logo",                 href: "https://www.lutron.com/"                     },
        { src: OriginAcustic,       alt: "Origin Acoustics Logo",       href: "https://www.originacoustics.com/"            },
        { src: Savant,              alt: "Savant Logo",                 href: "https://www.savant.com/"                     },
        { src: Seura,               alt: "Seura Logo",                  href: "https://www.seura.com/"                      },
        { src: Sonos,               alt: "Sonos Logo",                  href: "https://www.sonos.com/"                      },
        { src: Sony,                alt: "Sony Logo",                   href: "https://www.sony.com/"                       },
        { src: Trinnov,             alt: "Trinnov Logo",                href: "https://www.trinnov.com/"                    },
        { src: Wattbox,             alt: "Wattbox Logo",                href: "https://www.snapav.com/shop/en/snapav/wattbox" }
    ];

    const first6Logos = [...logos.slice(0, 6), ...logos.slice(0, 6), ...logos.slice(0, 6)];
    const second6Logos = [...logos.slice(6, 12), ...logos.slice(6, 12), ...logos.slice(6, 12)];
    const last6Logos = [...logos.slice(12, 18), ...logos.slice(12, 18), ...logos.slice(12, 18)];

    const first3Logos = [...logos.slice(0, 3), ...logos.slice(0, 3), ...logos.slice(0, 3)];
    const second3Logos = [...logos.slice(3, 6), ...logos.slice(3, 6), ...logos.slice(3, 6)];
    const third3Logos = [...logos.slice(6, 9), ...logos.slice(6, 9), ...logos.slice(6, 9)];
    const fourth3Logos = [...logos.slice(9, 12), ...logos.slice(9, 12), ...logos.slice(9, 12)];
    const fifth3Logos = [...logos.slice(12, 15), ...logos.slice(12, 15), ...logos.slice(12, 15)];
    const last3Logos = [...logos.slice(15, 18), ...logos.slice(15, 18), ...logos.slice(15, 18)];

    const logoStyle =
    {
        width: "150px",
        margin: "0 1rem",
        flexShrink: 0,
    };

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-4 mb-4 mx-auto">
                        {/* Our Companies */}
                        <h5 className="mt-4 fw-bold text-center">Our Other Companies</h5>
                        <div className='row'>
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://sts.vherngames.com/">
                                    <img src={STSLogo} style={{ width: "190px" }} alt="STS Logo" />
                                </a>
                            </div>
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://scs.vherngames.com/">
                                    <img src={SCSLogo} style={{ width: "190px" }} alt="SCS Logo" />
                                </a>
                            </div>
                        </div>

                        {/* Scrolling Logos - Desktop */}
                        <div className="d-none d-md-block py-4">
                            <h5 className="fw-bold text-center mb-3">Our Trusted Partners</h5>
                            {/* Scrolling Logos - First Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {first6Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Second Row (Reverse Direction) */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content-reverse">
                                        {second6Logos.map((logo, index) => (
                                            <a key={`bottom-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Third Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {last6Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Scrolling Logos - Mobile */}
                        <div className="d-block d-md-none py-4">
                            <h5 className="fw-bold text-center mb-3">Our Trusted Partners</h5>
                            {/* Scrolling Logos - First Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {first3Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Second Row (Reverse Direction) */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content-reverse">
                                        {second3Logos.map((logo, index) => (
                                            <a key={`bottom-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Third Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {third3Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Fourth Row (Reverse Direction) */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content-reverse">
                                        {fourth3Logos.map((logo, index) => (
                                            <a key={`bottom-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Fifth Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {fifth3Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Sixth Row (Reverse Direction) */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content-reverse">
                                        {last3Logos.map((logo, index) => (
                                            <a key={`bottom-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Second Column: Modern Links with hover effects */}
                    <div className="col-12 col-lg-6 col-xl-4 mb-4 d-flex justify-content-center">
                        <ul className="list-unstyled text-left">
                            <li className="mb-3"><a href="/smart-home-automation" className="animated-link">Smart Home Automation</a></li>
                            <li className="mb-3"><a href="/commercial-automation" className="animated-link">Commercial Automation</a></li>
                            <li className="mb-3"><a href="/automation-wellness" className="animated-link">Wellness Tech</a></li>
                            <li className="mb-3"><a href="/spaces-home" className="animated-link">Home Spaces</a></li>
                            <li className="mb-3"><a href="/spaces-commercial" className="animated-link">Commercial Spaces</a></li>
                            <li className="mb-3"><a href="/spaces-mdu" className="animated-link">MDU (Multi-Dwelling Units)</a></li>
                            <li className="mb-3"><a href="building-dashboard.vherngames.com" className="animated-link">Smart Building Dashboard</a></li>
                            <li className="mb-3"><a href="/services" className="animated-link">Services</a></li>
                            <li className="mb-3"><a href="/user-experience" className="animated-link">User Experience</a></li>
                            <li className="mb-3"><a href="/gallery" className="animated-link">Gallery</a></li>
                            <li className="mb-3"><a href="/#contactUs" className="animated-link">Contact</a></li>
                        </ul>
                    </div>

                    {/* Third Column: Reviews and Ratings */}
                    <div className="col-lg-12 col-xl-4 mb-4 text-center order-md-last">
                        <div className="mb-3">
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                        </div>
                        <p className="smallLetter">Smart Tech Solutions is rated 5 out of 5 based on xx reviews from satisfied clients.</p>
                        <p>"The team at Smart Tech Solutions transformed our home with incredible automation and support. Their expertise made everything effortless!"</p>
                        <div className="mb-2">
                            <GoogleLogo style={{ width: '3rem' }} alt="Google Logo" />
                        </div>
                        <p>– John D.</p>
                        <p className="accentColor animated-link">View full review here</p>
                    </div>
                </div>

                <div className="row">
                    <div id="divider" className="col-12 mb-3"></div>

                    {/* Social Media and Footer Links */}
                    <div className="col-12 col-md-6 mb-2 text-center text-md-left">
                        <a href="#" className="animated-link">Instagram</a> | <a href="#" className="animated-link">Twitter</a>
                    </div>
                    <div className="col-12 col-md-6 mb-2 text-center text-md-right">
                        <a href="#" className="animated-link">Privacy Policy</a> | <a href="/sitemap.xml" className="animated-link">Sitemap</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
